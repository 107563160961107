import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { NavContext } from '../util/navContext';
import { getAllPdfs } from "../dataSource/PDFSource";
import "./styling/home.css";
import "./styling/home-s.css";
import insta from "./assets/insta.svg";
import mail from "./assets/mail.svg";
import phone from "./assets/phone.svg";
import pinOrange from "./assets/pin-orange.svg";
import AOS from "aos";

import logo from "./assets/logoLemons.png";
import xCross from '../components/assets/x.svg';

//import bg2 from './assets/bg2.jpg';
import bg2 from "./assets/gin-bg.jpg";
import liquor from "./assets/liqour.jpg";
import liquorNew from "./assets/liqourNewest.jpg";
import restraunt from "./assets/restraunt.jpg";
import liquorStore from "./assets/liquorStore.jpg";
import satiata from "./assets/satiataRestraunt.jpg";
import { isPromise } from "selenium-webdriver/lib/promise";

function Home({ onLoadComplete }) {
  
  const [isLoaded, setIsLoaded] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [reserveSelect, isReserveSelect] = useState(false);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const { handleLinkClick, isHome } = useContext(NavContext);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const urls = [bg2, liquorNew, restraunt, liquorStore, satiata];

  function preloadLogo() {
    const img = new Image();
    img.src = logo;
    img.onload = () => {
      setLogoLoaded(true);
      preloadImages();
    };
    img.onerror = () => {
      console.error(`Error loading image: ${logo}`);
    };
  }

  function preloadImages() {
    let loadedCount = 0;
    const total = urls.length;

    urls.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedCount++;
        if (loadedCount === total) {
          const timer = setTimeout(() => {
            setIsLoaded(true);
            document.documentElement.style.overflow = "auto";
            AOS.refreshHard();
            onLoadComplete();
          }, 2000);
        }
      };
      img.onerror = () => {
        console.error(`Error loading image: ${url}`);
      };
    });
  }

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    preloadLogo();
  }, []);

  return (
    <div id="homeWrapper">

      { reserveSelect && (
        <div id="menuSelectorBox">
          <div className="menuSelectorWrapper">
            <img onClick={() => isReserveSelect(false)} src={xCross} alt="x"></img>

            <h3>Waar wil je een tafel reserveren?</h3>

            <div className="menuSelectButtons">
               <a
                  href="https://restaurant-pulcinos.lurch.app/reservation/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="btn-pulci" >Pulcinos</button>
                </a>
                <a
                  href="https://pulcinos.lurch.app/reservation/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="btn-satiata" >Satiata</button>
                </a>
            </div>
          </div>
        </div>
      )}      

      <div id="splashScreen" className={isLoaded ? "fade-out" : ""}>
        {logoLoaded && <img data-aos="zoom-in" src={logo} alt="" />}
      </div>

      <div id="homeOverlay">
        <div></div>
      </div>

      <div id="homeContent">
        <div className="highlights">
          <Link
            onClick={() => handleLinkClick(isHome)}
            className="hightlightsLink"
            to="/liquors#liquorBox"
            style={{ textDecoration: "none" }}
          >
            <div id="liqourDiv">
              <img src={liquorNew} alt=""></img>

              <h2>Liquors</h2>
            </div>
          </Link>

          <Link
          onClick={() => handleLinkClick(isHome)}
            className="hightlightsLink"
            to="/restaurant#restrauntAnchor"
            style={{ textDecoration: "none" }}
          >
            <div id="restrauntDiv">
              <img src={restraunt} alt=""></img>

              <h2>Restaurant</h2>
            </div>
          </Link>

          <Link
          onClick={() => handleLinkClick(isHome)}
            className="hightlightsLink"
            to="/satiata/store#storeBox"
            style={{ textDecoration: "none" }}
          >
            <div id="liqourStoreDiv">
              <img src={liquorStore} alt=""></img>

              <h2>Satiata Liquorstore</h2>
            </div>
          </Link>

          <Link
          onClick={() => handleLinkClick(isHome)}
            className="hightlightsLink"
            to="/satiata/restaurant#satiataBox"
            style={{ textDecoration: "none" }}
          >
            <div id="satiataDiv">
              <img src={satiata} alt=""></img>

              <h2>Satiata&nbsp; Restaurant</h2>
            </div>
          </Link>
        </div>

        <div className="btnBox">

          {isMobile ? (
            <>
            
            <button onClick={() => isReserveSelect(true)}>Reserveer een Tafel</button>

            <div className="contactSplitter">

            <div className="btnWrapper">
            <div className="addresses">
              <a
                href="https://maps.app.goo.gl/uMM7iNZJsabxVUm5A"
                rel="noreferrer"
                target="_blank"
              >
                <div className="addressWrapper">
                  <div className="addressImageWrapper">
                    <img src={pinOrange} alt=""></img>
                  </div>
                  <div className="textBox">
                    <span>
                      <strong>Pulcinos Tilburg</strong>
                    </span>
                    <span>Piushaven 20B</span>
                  </div>
                </div>
              </a>
              <a
                href="https://maps.app.goo.gl/duKn74KsMC3eNcXWA"
                rel="noreferrer"
                target="_blank"
              >
                <div className="addressWrapper">
                  <div className="addressImageWrapper">
                    <img src={pinOrange} alt=""></img>
                  </div>
                  <div className="textBox">
                    <span>
                      <strong>Satiata Restaurant</strong>
                    </span>
                    <span>Heuvelstraat 131</span>
                  </div>
                </div>
              </a>
              <a
                  href="https://maps.app.goo.gl/jbF4CZwr4GSjtqRHA"
                  rel="noreferrer"
                  target="_blank"
                >
              <div className="addressWrapper">
                <div className="addressImageWrapper">
                  <img src={pinOrange} alt=""></img>
                </div>
                <div className="textBox">
                  <span>
                    <strong>Satiata Liquorstore</strong>
                  </span>
                  <span>Heuvelstraat 133</span>
                </div>
              </div>
              </a>
            </div>
            </div>

            <div className="contactWrapper">
            <div className="contactDiv">
            <a href="tel:0135914906">
              <div className="contactSection">
                <div className="addressImageWrapper">
                  <img src={phone} alt=""></img>
                </div>
                <span>
                  <strong>013 591 4906</strong>
                </span>
              </div>
              </a>
              <a href="mailto:contact@pulcinos.nl">
              <div className="contactSection">
                <div className="addressImageWrapper">
                  <img src={mail} alt=""></img>
                </div>
                <span>
                  <strong>contact@pulcinos.nl</strong>
                </span>
              </div>
              </a>
            </div>
            <a
                href="https://www.instagram.com/pulcinostilburg/"
                target="_blank"
                rel="noopener noreferrer"
              >
            <div className="instaLogoWrapper">             
                <img id="instaLogo" src={insta} alt="instagram"></img>             
            </div>
            </a>
          </div>
          </div>
            </>
          ) : (
            <>

          <div className="btnWrapper">
            <div className="addresses">
              <a
                href="https://maps.app.goo.gl/uMM7iNZJsabxVUm5A"
                rel="noreferrer"
                target="_blank"
              >
                <div className="addressWrapper">
                  <div className="addressImageWrapper">
                    <img src={pinOrange} alt=""></img>
                  </div>
                  <div className="textBox">
                    <span>
                      <strong>Pulcinos Tilburg</strong>
                    </span>
                    <span>Piushaven 20B</span>
                  </div>
                </div>
              </a>
              <a
                href="https://maps.app.goo.gl/duKn74KsMC3eNcXWA"
                rel="noreferrer"
                target="_blank"
              >
                <div className="addressWrapper">
                  <div className="addressImageWrapper">
                    <img src={pinOrange} alt=""></img>
                  </div>
                  <div className="textBox">
                    <span>
                      <strong>Satiata Restaurant</strong>
                    </span>
                    <span>Heuvelstraat 131</span>
                  </div>
                </div>
              </a>
              <a
                  href="https://maps.app.goo.gl/jbF4CZwr4GSjtqRHA"
                  rel="noreferrer"
                  target="_blank"
                >
              <div className="addressWrapper">
                <div className="addressImageWrapper">
                  <img src={pinOrange} alt=""></img>
                </div>
                <div className="textBox">
                  <span>
                    <strong>Satiata Liquorstore</strong>
                  </span>
                  <span>Heuvelstraat 133</span>
                </div>
              </div>
              </a>
            </div>
          </div>
          
          <button onClick={() => isReserveSelect(true)}>Reserveer een Tafel</button>
       
          <div className="contactWrapper">
            <div className="contactDiv">
            <a href="tel:0135914906">
              <div className="contactSection">
                <div className="addressImageWrapper">
                  <img src={phone} alt=""></img>
                </div>
                <span>
                  <strong>013 591 4906</strong>
                </span>
              </div>
              </a>
              <a href="mailto:contact@pulcinos.nl">
              <div className="contactSection">
                <div className="addressImageWrapper">
                  <img src={mail} alt=""></img>
                </div>
                <span>
                  <strong>contact@pulcinos.nl</strong>
                </span>
              </div>
              </a>
            </div>
            <a
                href="https://www.instagram.com/pulcinostilburg/"
                target="_blank"
                rel="noopener noreferrer"
              >
            <div className="instaLogoWrapper">             
                <img id="instaLogo" src={insta} alt="instagram"></img>             
            </div>
            </a>
          </div>
          </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styling/restraunt.css";
import './styling/restraunt-s.css';
import pdfMenuPulcinos from "../pdf/latest_menu_pulcinos.pdf";
import mapsIconPulci from "./assets/mapsPulci.svg";
import pdfMenuSatiata from "../pdf/latest_menu_satiata.pdf";
import ContactForm from "../components/ContactForm";
import mail from "./assets/mail-s.svg";
import insta from "./assets/insta-s.svg";
import phone from "./assets/phone-s.svg";
import xCross from '../components/assets/x.svg';


function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );
  const [reserveSelect, isReserveSelect] = useState(false);

  const handleOpenPdf = (restaurant) => {
    if (restaurant == "pulcinos") {
      if (pdfMenuPulcinos) {
        window.open(pdfMenuPulcinos, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    } else if (restaurant == "satiata") {
      if (pdfMenuSatiata) {
        window.open(pdfMenuSatiata, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    }
  };

  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL (e.g., #team)
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>

    { reserveSelect && (
      <div id="menuSelectorBox">
        <div className="menuSelectorWrapper">
          <img onClick={() => isReserveSelect(false)} src={xCross} alt="x"></img>

          <h3>Waar wil je een tafel reserveren?</h3>

          <div className="menuSelectButtons">
            <a
                href="https://restaurant-pulcinos.lurch.app/reservation/"
                rel="noreferrer"
                target="_blank"
              >
                <button className="btn-pulci" >Pulcinos</button>
              </a>
              <a
                href="https://pulcinos.lurch.app/reservation/"
                rel="noreferrer"
                target="_blank"
              >
                <button className="btn-satiata" >Satiata</button>
              </a>
          </div>
        </div>
      </div>
    )}      

      {!isMobile && (
    <div className="desktopNavSpacer"></div>
     )}
     
      <div id="contactBox">
        <div id="contactTitleWrapper">
          <h1>Contact</h1>
        </div>

        <div id="contactSplitter">
          <div id="contactInfoWrapper">
            <h2>Waar kunnen we je mee helpen?</h2>

            <p>
              Heb je een vraag voor Pulcinos Tilburg of Satiata Tilburg? Ons
              team van enthousiaste medewerkers staat voor je klaar. Wij zijn
              donderdag tot en met maandag telefonisch bereikbaar van 12:00 tot
              00:00. Op dinsdag en woensdag zijn wij gesloten.
            </p>

            <p>
              <i>
                Let op: Wil je een tafel reserveren in ons restaurant?
                Reserveringen voor in ons restaurant kunnen uitsluitend worden
                verwerkt via de reserveringspagina of telefonisch via
                013-591-4906.
              </i>
            </p>

            <a
              onClick={() => isReserveSelect(true)}
              rel="noreferrer"
              target="_blank"
            >
              Klik hier om te reserveren
            </a>

            <div id="addressWrapper">
              <div className="addressCol">
                <h3>Pulcinos Tilburg</h3>
                <br></br>
                <a
                  href="https://maps.app.goo.gl/uMM7iNZJsabxVUm5A"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="mapsSplitter">
                    <div>
                      <img src={mapsIconPulci} alt=""></img>
                    </div>
                    <div>
                      <p>Piushaven 20B</p>
                      <p>5017 AN, Tilburg</p>
                    </div>
                  </div>
                </a>
                <br></br>
                <div id="openingWrapper">
                  <div className="openingCol">
                    <p>Ma</p>
                    <p>Di</p>
                    <p>Wo</p>
                    <p>Do</p>
                    <p>Vr</p>
                    <p>Za</p>
                    <p>Zo</p>
                  </div>

                  <div className="openingCol">
                    <p>17:00 - 23:00</p>
                    <p>Gesloten</p>
                    <p>17:00 - 23:00</p>
                    <p>17:00 - 23:00</p>
                    <p>12:00 - 00:00</p>
                    <p>12:00 - 00:00</p>
                    <p>12:00 - 00:00</p>
                  </div>
                </div>
                <br></br>
                <a href="tel:0135914906">
                  <p>
                    <img src={phone} alt=""></img>013-591-4906
                  </p>
                </a>
                <a href="mailto:contact@pulcinos.nl">
                  <p>
                    <img src={mail} alt=""></img>contact@pulcinos.nl
                  </p>
                </a>
                <a
                  href="https://www.instagram.com/pulcinostilburg/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>
                    <img src={insta} alt=""></img>@pulcinostilburg
                  </p>
                </a>
              </div>
              {isMobile && (
                <div className="addressStripe"></div>
              )}
              <div className="addressCol">
                <h3>Satiata Restaurant</h3>
                <br></br>
                <a
                  href="https://maps.app.goo.gl/duKn74KsMC3eNcXWA"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="mapsSplitter">
                    <div>
                      <img src={mapsIconPulci} alt=""></img>
                    </div>
                    <div>
                      <p>Nieuwlandstraat 59</p>
                      <p>5038 SM, Tilburg</p>
                    </div>
                  </div>
                </a>
                <br></br>
                <div id="openingWrapper">
                  <div className="openingCol">
                    <p>Ma</p>
                    <p>Di</p>
                    <p>Wo</p>
                    <p>Do</p>
                    <p>Vr</p>
                    <p>Za</p>
                    <p>Zo</p>
                  </div>

                  <div className="openingCol">
                    <p>Gesloten</p>
                    <p>Gesloten</p>
                    <p>17:00 - 23:00</p>
                    <p>17:00 - 23:00</p>
                    <p>13:00 - 23:00</p>
                    <p>13:00 - 23:00</p>
                    <p>13:00 - 23:00</p>
                  </div>
                </div>
                <br></br>
                <a href="tel:+31638732943">
                  <p>
                    <img src={phone} alt=""></img>06-38732943
                  </p>
                </a>
                <a href="mailto:satiatatilburg@gmail.com">
                  <p>
                    <img src={mail} alt=""></img>satiatatilburg@gmail.com
                  </p>
                </a>
                <a
                  href="https://www.instagram.com/satiata.tilburg/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>
                    <img src={insta} alt=""></img>@satiata.tilburg
                  </p>
                </a>
              </div>
              {isMobile && (
                <div className="addressStripe"></div>
              )}
              <div className="addressCol">
                <h3>Satiata Wine & Liquor</h3>
                <br></br>
                <a
                  href="https://maps.app.goo.gl/jbF4CZwr4GSjtqRHA"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="mapsSplitter">
                    <div>
                      <img src={mapsIconPulci} alt=""></img>
                    </div>
                    <div>
                      <p>Heuvelstraat 131</p>
                      <p>5031 AD, Tilburg</p>
                    </div>
                  </div>
                </a>
                <br></br>
                <div id="openingWrapper">
                  <div className="openingCol">
                    <p>Ma</p>
                    <p>Di</p>
                    <p>Wo</p>
                    <p>Do</p>
                    <p>Vr</p>
                    <p>Za</p>
                    <p>Zo</p>
                  </div>

                  <div className="openingCol">
                    <p>12:30 - 17:00</p>
                    <p>Gesloten</p>
                    <p>Gesloten</p>
                    <p>12:30 - 17:00</p>
                    <p>12:30 - 17:00</p>
                    <p>12:30 - 17:00</p>
                    <p>12:30 - 17:00</p>
                  </div>
                </div>
                <br></br>
                <a href="tel:0132045111">
                  <p>
                    <img src={phone} alt=""></img>013-204-5111
                  </p>
                </a>
                <a href="mailto:contact@pulcinos.nl">
                  <p>
                    <img src={mail} alt=""></img>contact@pulcinos.nl
                  </p>
                </a>
                <a
                  href="https://www.instagram.com/satiatastore/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>
                    <img src={insta} alt=""></img>@satiatastore
                  </p>
                </a>
              </div>
            </div>
          </div>

          <ContactForm isReserveSelect={isReserveSelect}></ContactForm>
        </div>
      </div>
    </>
  );
}

export default Contact;

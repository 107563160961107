const BASE_URL = 'https://deieznoi.a2hosted.com/dataservice';
const maxRetries = 5;
const retryDelay = 1000;

async function fetchWithRetry(url, options = {}, retries = maxRetries) {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await fetch(url, options);
      if (response.ok) return response; // Success
      console.error(`Attempt ${attempt} failed: ${response.statusText}`);
    } catch (error) {
      console.error(`Attempt ${attempt} failed with error:`, error);
      if (attempt === retries) throw error; // Re-throw if final attempt
    }
    // Wait before next attempt
    await new Promise(resolve => setTimeout(resolve, retryDelay));
  }
}

// Fetch an order by ID
export async function getOrderById(id) {
  try {
    const response = await fetch(`${BASE_URL}/order/${id}`);
    //const response = await fetch(`http://localhost:5009/order/${id}`);
    if (response.ok) {
      const order = await response.json();
      return {
        ...order,
        customer: order.customer,
        address: order.address,
        order_lines: order.order_lines,
      };
    } else {
      console.error('Failed to fetch order details');
      return false;
    }
  } catch (error) {
    console.error('Error fetching order details:', error);
    return false;
  }
}

// Fetch all orders
export async function getAllOrders() {
  try {
    const response = await fetch(`${BASE_URL}/orders`);
    if (response.ok) {
      const orders = await response.json();
      return orders.map(order => ({
        ...order,
        customer: {
          email_address: order.customer.email_address
        },
        address: {
          city: order.address.city
        }
      }));
    } else {
      console.error('Failed to fetch orders');
      return false;
    }
  } catch (error) {
    console.error('Error fetching orders:', error);
    return false;
  }
}

// Add a new order
// export async function addOrder(orderPrice, note, customerData, addressData, shippingAddressData, shippingData, orderLines, method, redirectUrl) {
//   console.log("Placing order...");
//   try {
//     const response = await fetch(`${BASE_URL}/order`, {
//     //const response = await fetch(`http://localhost:5009/order`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         orderPrice,
//         note,
//         customerData,
//         addressData,
//         shippingAddressData,
//         shippingData,
//         orderLines,
//         method,
//         redirectUrl
//     }),
//     });

//     if (response.ok) {
//       const newOrder = await response.json();
//       console.log(newOrder);
//       return newOrder;
//       return("REEee");
//     } else {
//       console.error('Failed to add order');
//       return false;
//     }
//   } catch (error) {
//     console.error('Error adding order:', error);
//     return false;
//   }
// }


export async function addOrder(orderPrice, note, customerData, addressData, shippingAddressData, shippingData, orderLines, method, redirectUrl) {
  try {
     const response = await fetchWithRetry(`${BASE_URL}/order`, {
    //const response = await fetch(`http://localhost:5009/order`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ orderPrice, note, customerData, addressData, shippingAddressData, shippingData, orderLines, method, redirectUrl })
    });
    
    if (response) {
      const newOrder = await response.json();
      console.log(newOrder);
      return newOrder;
    } else {
      console.error('Failed to add order after retries');
      return false;
    }
  } catch (error) {
    console.error('Error adding order:', error);
    return false;
  }
}



// Update an order by ID
export async function updateOrder(orderId, data) {

  try {
    const response = await fetch(`${BASE_URL}/order/${orderId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return true;
    } else {
      console.error('Failed to update order');
      return false;
    }
  } catch (error) {
    console.error('Error updating order:', error);
    return false;
  }
}

// Download order PDF
export async function downloadOrderPdf(orderId) {
  try {
    const response = await fetch(`${BASE_URL}/order/${orderId}/pdf`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    });
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `order_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      return true;
    } else {
      console.error('Failed to download order PDF');
      return false;
    }
  } catch (error) {
    console.error('Error downloading order PDF:', error);
    return false;
  }
}
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styling/footer.css";

import  { getActivePdf } from '../dataSource/PDFSource';

import pinBlue from "../pages/assets/pin-blue.svg";
import insta from "../pages/assets/insta.svg";

import xCross from "./assets/x.svg";
import logo from "./assets/logoTrans.png";

import pdfMenuSatiata from "../pdf/latest_menu_satiata.pdf";
import pdfMenuPulcinos from "../pdf/latest_menu_pulcinos.pdf";

function Footer() {
  const [menuSelect, isMenuSelect] = useState(false);
  const [reserveSelect, isReserveSelect] = useState(false);
  const [isOpeningMenuPulcinos, setOpeningMenuPulcinos] = useState(false);
  const [isOpeningMenuSatiata, setOpeningMenuSatiata] = useState(false);

  let isFetchingPdf = false; // Prevent multiple simultaneous calls

  const handleOpenPdf = async (restaurant) => {
    if (isFetchingPdf) {
      console.log("Already fetching the PDF. Exiting early.");
      return; // Prevent concurrent executions
    }

    if(restaurant === 'pulcinos'){
      setOpeningMenuPulcinos(true);
    }else if(restaurant === 'satiata'){
      setOpeningMenuSatiata(true);
    }
  
    console.log("Starting to fetch the PDF...");
    isFetchingPdf = true; // Lock the execution
  
    const maxRetries = 3; // Maximum number of retry attempts
    const retryDelay = 2000; // Delay between retries in milliseconds
  
    const fetchWithRetry = async (retries) => {
      for (let attempt = 1; attempt <= retries; attempt++) {
        console.log(`Attempt ${attempt} to fetch the menu for ${restaurant}`);
        try {
          const menu = await getActivePdf(restaurant);
          console.log(`Menu fetched (Attempt ${attempt}):`, menu);
          if (menu && typeof menu === "string" && (menu.startsWith("http") || menu.startsWith("blob:"))) {
            console.log("Valid menu URL found. Returning the menu.");
            return menu; // Return the menu URL if valid
          } else {
            console.log(`Invalid menu URL (Attempt ${attempt}):`, menu);
          }
        } catch (error) {
          console.error(`Error during fetch (Attempt ${attempt}):`, error);
        }
  
        // Wait before retrying (skip delay after the last attempt)
        if (attempt < retries) {
          console.log(`Waiting ${retryDelay}ms before next attempt...`);
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
        }
      }
      console.log("All attempts failed. Returning null.");
      return null; // Return null if all retries fail
    };
  
    try {
      const menu = await fetchWithRetry(maxRetries);
  
      if (menu) {
        console.log("Opening the menu:", menu);
        
        const link = document.createElement("a");
        link.href = menu; // The blob URL
        link.target = "_blank"; // Open in a new tab
        link.rel = "noopener noreferrer";

        // set name for download
        link.download = "Menu_Pulcinos.pdf";

        // Trigger the download or navigation
        link.click();
        console.log("Menu opened successfully. Exiting function.");
        return; // Exit the function immediately after opening the menu
      } else {
        console.log("Failed to fetch the menu after all retries.");
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    } finally {
      console.log("Resetting the isFetchingPdf flag.");
      isFetchingPdf = false; // Unlock execution after completing
      
    if(restaurant === 'pulcinos'){
      setOpeningMenuPulcinos(false);
    }else if(restaurant === 'satiata'){
      setOpeningMenuSatiata(false);
    }
    }
  };
 
  

  return (
    <footer>
      {menuSelect && (
        <div id="menuSelectorBox">
          <div className="menuSelectorWrapper">
            <img onClick={() => isMenuSelect(false)} src={xCross} alt="x"></img>

            <h3>Welk menu wil je bekijken?</h3>

            <div className="menuSelectButtons">
              <button onClick={() => handleOpenPdf("pulcinos")}
                className="btn-pulci"
                disabled={isOpeningMenuPulcinos}>
                Menu Pulcinos
              </button>
              <button onClick={() => handleOpenPdf("satiata")}
                className="btn-satiata"
                disabled={isOpeningMenuSatiata}>
                Menu Satiata
              </button>
            </div>
          </div>
        </div>
      )}

      {reserveSelect && (
        <div id="menuSelectorBox">
          <div className="menuSelectorWrapper">
            <img onClick={() => isReserveSelect(false)} src={xCross} alt="x"></img>

            <h3>Waar wil je een tafel reserveren?</h3>

            <div className="menuSelectButtons">
               <a
                  href="https://restaurant-pulcinos.lurch.app/reservation/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="btn-pulci" >Pulcinos</button>
                </a>
                <a
                  href="https://pulcinos.lurch.app/reservation/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="btn-satiata" >Satiata</button>
                </a>
            </div>
          </div>
        </div>
      )}

      <div className="footerCol">
        <img className="footerLogo" src={logo} alt="Pulcinos logo"></img>

        <div className="footerHotLinks">
          <a onClick={() => isMenuSelect(true)}>
            <p>Menu</p>
          </a>
         <p className="yellow linkSpacer"> | </p>
          <Link to="/contact">
            <p>Contact</p>
          </Link>
        </div>

        <button className="checkout-btn" onClick={() => isReserveSelect(true)}>Reserveer een Tafel</button>
      </div>

      <p className="copyRight">© Pulcinos 2024 ⋅ Alle rechten voorbehouden.</p>
    </footer>
  );
}

export default Footer;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styling/restraunt.css";
import './styling/restraunt-s.css';
import pdfMenuPulcinos from "../pdf/latest_menu_pulcinos.pdf";
import pdfMenuSatiata from "../pdf/latest_menu_satiata.pdf";
import about from "./assets/about.jpg";

function About() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const handleOpenPdf = (restaurant) => {
    if (restaurant == "pulcinos") {
      if (pdfMenuPulcinos) {
        window.open(pdfMenuPulcinos, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    } else if (restaurant == "satiata") {
      if (pdfMenuSatiata) {
        window.open(pdfMenuSatiata, "_blank");
      } else {
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    }
  };

  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL (e.g., #team)
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
    {!isMobile && (
    <div className="desktopNavSpacer"></div>
     )}
     
      <div id="aboutBox">
        <div id="aboutTitleWrapper">
          <h1>
            Over <span className="yellow">Pulcinos</span>
          </h1>
        </div>

        <div id="aboutSplitter">

        {!isMobile && (
          <div id="aboutImageSlider">
            <img src={about} alt="about"></img>
          </div>
        )}

          <div id="aboutInfo">

            <h2>Hoe het allemaal is begonnen...</h2>

            <p>
            Pulcinos Liquor is geboren uit een liefde voor Sicilië, familie, en traditie. 
            In Falcone, een charmant dorpje op het Italiaanse eiland Sicilië, maakte een oom 
            volgens een oud familierecept limoncello met Siciliaanse citroenen. Zijn passie 
            voor het eiland en zijn ambachtelijke limoncello lieten een diepe indruk achter. 
            Helaas overleed hij in 2012, maar de liefde die hij had voor het eiland en 
            zijn limoncello leeft voort in onze familie.
            </p>

            <p>
            Als we Sicilië bezochten, proefden we zijn creaties en genoten we van de lokale keuken. 
            Op het eiland kreeg ik de bijnaam “Pulcino,” Italiaans voor kuikentje, vanwege mijn 
            goudblonde haar dat door de zon oplichtte. Op mijn 16e leerde ik van Francesco Milone, 
            de vader van mijn oom, de geheimen van het maken van limoncello. Thuis in Nederland begon 
            ik te experimenteren en maakte ik de klassieke limoncello maar ook eigen versies, die al 
            snel geliefd werd bij familie en vrienden.{" "}
            </p>

            <p>              
            In 2018 besloot ik mijn passie te delen met de wereld en lanceerde ik Pulcinos 
            Liquor, een limoncello die trouw blijft aan de Siciliaanse traditie. Het resultaat 
            is een eerlijke en frisse drank met de beste ingrediënten, perfect om te delen tijdens 
            bijzondere momenten.
            </p>

          <br></br>
          {isMobile && (
          <div id="aboutImageSlider">
            <img src={about} alt="about"></img>
          </div>
          )}
          <br></br>

          <h2>Mijn ambitie kunnen waarmaken...</h2>

          <p>
          Naast Pulcinos Liquor bleef mijn liefde voor de horeca groeien. 
          Met jarenlange ervaring als chef-kok droomde ik van een plek waar eten, 
          drinken en gezelligheid samenkomen. In augustus 2020 werd die droom werkelijkheid 
          met de opening van <b className="yellow">Pulcinos Food</b>.
          </p>

          <p>
          Pulcinos Food is een knusse, laagdrempelige zaak aan de Piushaven waar we werken met eerlijke en lokale producten.
          Ook is in 2024 Satiata Tilburg geopend, Satiata is het kleine zusje van Pulcinos, gelegen aan de 
          Nieuwlandstraat in Tilburg. In onze gezellige, intieme zaak serveren we handgemaakte, verse gerechtjes 
          die perfect te combineren zijn met een groot assortiment aan wijnen. De wijnen komen van eigen slijterij, 
          pal naast de deur. Samen met chef Puck van Baast bij Pulcinos en Mattijs van der Meulen 
          bij Satiata bereiden we een klein, wisselend menu met dagverse ingrediënten. Of je nu komt voor een 
          ontspannen borrel, een smaakvol diner, lunch of een goed gesprek, je bent altijd van harte welkom.
          </p>

          <p>
          Kom een keer gezellig langs bij Pulcinos Piushaven, Satiata restaurant of probeer een van onze dranken. 
          Onze dranken zijn te koop in onze slijterij aan de heuvelstraat of op onze webshop. 
          </p>

          <p>
          Met Pulcinos Liquor brengen we een stukje Sicilië naar Nederland. </p>

          <p>Salute!</p>

          </div>
        </div>

        {/* <div id="aboutBottomText">
          <h2>Mijn ambitie kunnen waarmaken...</h2>

          <p>
          Naast Pulcinos Liquor bleef mijn liefde voor de horeca groeien. 
          Met jarenlange ervaring als chef-kok droomde ik van een plek waar eten, 
          drinken en gezelligheid samenkomen. In augustus 2020 werd die droom werkelijkheid 
          met de opening van <b className="yellow">Pulcinos Food</b>.
          </p>
          <p>
          Pulcinos Food is een knusse, laagdrempelige zaak aan de Piushaven waar we werken met eerlijke en lokale producten.
          Ook is in 2024 Satiata Tilburg geopend, Satiata is het kleine zusje van Pulcinos, gelegen aan de 
          Nieuwlandstraat in Tilburg. In onze gezellige, intieme zaak serveren we handgemaakte, verse gerechtjes 
          die perfect te combineren zijn met een groot assortiment aan wijnen. De wijnen komen van eigen slijterij, 
          pal naast de deur. Samen met chef Puck van Baast bij Pulcinos en Mattijs van der Meulen 
          bij Satiata bereiden we een klein, wisselend menu met dagverse ingrediënten. Of je nu komt voor een 
          ontspannen borrel, een smaakvol diner, lunch of een goed gesprek, je bent altijd van harte welkom.
          </p>
          <p>
          Kom een keer gezellig langs bij Pulcinos Piushaven, Satiata restaurant of probeer een van onze dranken. 
          Onze dranken zijn te koop in onze slijterij aan de heuvelstraat of op onze webshop. 
          </p>
          <p>
          Met Pulcinos Liquor brengen we een stukje Sicilië naar Nederland. </p>
          <p>Salute!</p>
        </div> */}


      </div>

    </>
  );
}

export default About;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styling/restraunt.css";
import './styling/restraunt-s.css';
import ImageSlider from "../components/Slider";
import pdfMenuPulcinos from "../pdf/latest_menu_pulcinos.pdf";
import bg from "../components/assets/slider/2.jpg";
import bg2 from "../components/assets/slider/3.jpg";
import restraunt from "./assets/restraunt.jpg";
import limoncello from "./assets/limoncello.jpg";
import gin from "./assets/gin.jpg";
import yuzu from "./assets/yuzu.jpg";
import lemon from "./assets/lemon.png";
import lemonSlice from "./assets/lemonSliceTransparant.png";
import mapsIconPulci from "./assets/mapsPulci.svg";
import mapsIconSatiata from "./assets/mapsSatiata.svg";
import instaYellow from "./assets/insta-yellow.svg";
import glassBg from "./assets/glass-bg.jpg";

import  { getActivePdf } from '../dataSource/PDFSource';

import pdfMenuSatiata from "../pdf/latest_menu_satiata.pdf";
import gradientDown from "./assets/waves-down.svg";
import gradientUp from "./assets/waves-up.svg";
import gradientGreen from "./assets/waves-green.svg";
import ImageSliderSatiata from "../components/SliderSatiata";
import octo from "./assets/octo.JPG";
import logoSatiata from "./assets/logoSatiata.svg";
import instaRed from "./assets/insta-red.svg";

import storeFront from "./assets/liquorStoreFront.jpg";
import kunstRoof from "./assets/kunstroof-transparent.png";
import ImagesliderSatiataStore from "../components/SliderSatiataStore";
import logoBeige from "./assets/logoSatiataBeige.webp";

import logoTrans from "./assets/logoTrans.png";
import ContactForm from "../components/ContactForm";
import yuzuu from "./assets/yuzuu.webp";

import cirkles from "./assets/cirkles.svg";
import about from "./assets/about.jpg";

import mail from "./assets/mail-s.svg";
import insta from "./assets/insta-s.svg";
import phone from "./assets/phone-s.svg";


function Restraunt() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );
  const [isOpeningMenuPulcinos, setOpeningMenuPulcinos] = useState(false);

  let isFetchingPdf = false; // Prevent multiple simultaneous calls

  const handleOpenPdf = async (restaurant) => {

    const link = document.createElement("a");
    link.href = "";
    link.target = "_blank";
    document.body.appendChild(link);

    if (isFetchingPdf) {
      console.log("Already fetching the PDF. Exiting early.");
      return; // Prevent concurrent executions
    }

    setOpeningMenuPulcinos(true);
  
    console.log("Starting to fetch the PDF...");
    isFetchingPdf = true; // Lock the execution
  
    const maxRetries = 3; // Maximum number of retry attempts
    const retryDelay = 2000; // Delay between retries in milliseconds
  
    const fetchWithRetry = async (retries) => {
      for (let attempt = 1; attempt <= retries; attempt++) {
        console.log(`Attempt ${attempt} to fetch the menu for ${restaurant}`);
        try {
          const menu = await getActivePdf(restaurant);
          console.log(`Menu fetched (Attempt ${attempt}):`, menu);
          if (menu && typeof menu === "string" && (menu.startsWith("http") || menu.startsWith("blob:"))) {
            console.log("Valid menu URL found. Returning the menu.");
            return menu; // Return the menu URL if valid
          } else {
            console.log(`Invalid menu URL (Attempt ${attempt}):`, menu);
          }
        } catch (error) {
          console.error(`Error during fetch (Attempt ${attempt}):`, error);
        }
  
        // Wait before retrying (skip delay after the last attempt)
        if (attempt < retries) {
          console.log(`Waiting ${retryDelay}ms before next attempt...`);
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
        }
      }
      console.log("All attempts failed. Returning null.");
      return null; // Return null if all retries fail
    };
  
    try {
      const menu = await fetchWithRetry(maxRetries);
  
      if (menu) {
        console.log("Opening the menu:", menu);
       
        link.href = menu;
        link.download = `Menu_${restaurant}.pdf`;
        link.click();

          // Trigger the downlo
        console.log("Menu opened successfully. Exiting function.");
        return; // Exit the function immediately after opening the menu
      } else {
        console.log("Failed to fetch the menu after all retries.");
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    } finally {
      console.log("Resetting the isFetchingPdf flag.");
      isFetchingPdf = false; // Unlock execution after completing
      setOpeningMenuPulcinos(false);
    }
  };
 

  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL (e.g., #team)
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <div id="homeBox">
        <div className="navWrapper"></div>

        <img src={restraunt} alt="background" class="background-image"></img>

        <div id="restrauntAnchor" className="splitterBox">
          <div className="infoBox">
            <div className="infoWrapper">
              <h1>
                Welkom bij <span className="yellow">Pulcinos</span>
              </h1>

              <p>
                Aan de Piushaven in Tilburg vind je Restaurant Pulcinos, de
                perfecte plek om te genieten van lekker eten, goede drankjes en
                een ontspannen sfeer. Of je nu komt voor een diner, een borrel
                of een cocktail aan de bar, je bent voor alles welkom.
              </p>

              <p>
                We serveren smaakvolle gerechten gemaakt met verse ingrediënten
                en hebben een mooie selectie cocktails en borrelhapjes. Veel van
                de cocktails zijn gemaakt met onze eigen dranken zoals de
                Limoncello, Valenti en Yuzu gin. Veel gerechten zijn
                geïnspireerd door de Italiaanse keuken maar er word eten van
                over heel de wereld geserveerd.{" "}
              </p>

              <p>
                Kom langs en geniet van alles wat Pulcinos te bieden heeft – een
                fijne plek aan het water, goed eten, drinken en gezelligheid!
              </p>

              <p>Reserveer via de knop hieronder!</p>

              <div className="buttonsBox">
                <a
                  href="https://restaurant-pulcinos.lurch.app/reservation/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="checkout-btn">Reserveer een Tafel</button>
                </a>

                <button
                  className="checkout-btn"
                  onClick={() => handleOpenPdf("pulcinos")}
                  disabled={isOpeningMenuPulcinos}
                >
                  Bekijk Menu
                </button>

                <a
                  href="https://maps.app.goo.gl/uMM7iNZJsabxVUm5A"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="checkout-btn mapsButton">
                    <img src={mapsIconPulci} alt=""></img>
                    Adres
                  </button>
                </a>
                <a
                  href="https://www.instagram.com/pulcinostilburg/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img className="instaLogo" src={instaYellow} alt=""></img>
                </a>
              </div>
            </div>
          </div>

          <div className="imageBox">
            <div>
              <ImageSlider />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Restraunt;

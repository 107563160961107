import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./styling/register.css";

const Register = () => {
  const navigate = useNavigate();
  const [emailAddress, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    //const responseAccount = await fetch("http://127.0.0.1:5009/register", {
    const responseAccount = await fetch('https://deieznoi.a2hosted.com/dataservice/register', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName,
        emailAddress,
        password,
      }),
    });

    if (responseAccount.ok) {
      
      navigate(`/login`);
     
    } else {
      // Foutafhandeling
      console.error("Registering of user data failed");
    }
  };

  return (
    <div id="registerBox">
      <h2>Register</h2>
      <form onSubmit={handleSubmit}>
         <input
          type="text"
          placeholder="Username"
          required
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Email"
          required
          value={emailAddress}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Wachtwoord"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <button type="submit">Bevestigen</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  );
};

export default Register;

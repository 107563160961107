import React, { useState, useEffect, useContext, useRef } from "react";
import { NavContext } from '../util/navContext';
import { Link, useLocation } from "react-router-dom";
import  { getActivePdf } from '../dataSource/PDFSource';
import "./styling/nav.css";
import "./styling/nav-s.css";
import logoWhite from "./assets/logoLemons.png";
import NavCart from "./NavCart";
import x from "./assets/x-white.svg";
import burger from "./assets/list.svg";

import xCross from './assets/x.svg';
import logo from "./assets/logoTrans.png";

import pdfMenuSatiata from "../pdf/latest_menu_satiata.pdf";
import pdfMenuPulcinos from "../pdf/latest_menu_pulcinos.pdf";

function Nav() {

  const location = useLocation();
  const isHome = location.pathname === '/';
  const [menuSelect, isMenuSelect] = useState(false);
  const [reserveSelect, isReserveSelect] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navRef = useRef(null);
  const [isOpeningMenuPulcinos, setOpeningMenuPulcinos] = useState(false);
  const [isOpeningMenuSatiata, setOpeningMenuSatiata] = useState(false);

  const {
    isMobile,
    showMobileNav,
    setShowMobileNav,
    instaScroll,
    setInstaScroll,
    mobileNavCollapsed,
    setMobileNavCollapsed,
    isVisible,
    setIsVisible,
    handleLinkClick,
  } = useContext(NavContext);


  useEffect(() => {
    if(!isHome){
      setInstaScroll(false);
    }
  }, [instaScroll]);

  useEffect(() => {
    const handleScroll = () => {
      if (!isMobile) {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const spacerHeight = window.innerHeight * 0.2; // 20vh in pixels

        if (scrollTop > lastScrollTop && scrollTop > spacerHeight) {
          // Scrolling down, hide the navbar
          setIsVisible(false);
        } else if (scrollTop < lastScrollTop && scrollTop > spacerHeight) {
          // Scrolling up and past the 20vh spacer, show collapsed navbar
          setIsVisible(true);
          setIsCollapsed(true);
        } else if (scrollTop <= spacerHeight) {
          // Show full navbar when within the 20vh spacer
          setIsCollapsed(false);
          setIsVisible(true);

        }

        setLastScrollTop(scrollTop);
      } else {

        //if(!showMobileNav){

        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const spacerHeight = 100; // 100 pixels

        if (scrollTop > lastScrollTop && scrollTop > spacerHeight) {
          // Scrolling down, hide the navbar
          setMobileNavCollapsed(true);
          if(isHome){
            setInstaScroll(false);
          }
        } else if (scrollTop < lastScrollTop && scrollTop > spacerHeight) {
          // Scrolling up and past the 100px spacer, show nav
          setMobileNavCollapsed(false);
          if(isHome){
            setInstaScroll(false);
          }
        } else if (scrollTop <= spacerHeight) {
          setMobileNavCollapsed(false);
          
          if(isHome){
            setInstaScroll(true);
          }
        }
        setLastScrollTop(scrollTop);
      }
      //}
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  // New handler to hide navbar after a short delay when a link is clicked
  // const handleLinkClick = () => {

  //   if (isMobile) {
  //     setShowMobileNav(false);      
  //   setInstaScroll(false);
  //   }

  //   if (isHome){
  //     setMobileNavCollapsed(false);
  //   }

  //   document.documentElement.style.overflow = 'auto';
    
  //   window.scrollBy({ top: -100, behavior: "smooth" });

  //   setTimeout(() => {
  //     const scrollTop = window.scrollY || document.documentElement.scrollTop;
  //     const spacerHeight = window.innerHeight * 0.2;
  //     const mobileSpacerHeight = 100;

  //     if (!isMobile) {
  //       if (scrollTop >= spacerHeight) {
  //         setIsVisible(false);
  //       }
  //     } else {
  //       if (scrollTop >= mobileSpacerHeight) {         
  //         if(!isHome){            
  //         setMobileNavCollapsed(true);
  //         }
  //       }
  //     }
  //     // Hide the navbar after 500ms (adjust the delay to match your smooth scroll duration)
  //   }, 1500); // Delay to account for smooth scrolling
  // };

  const hideMobileNav = () => {
    setShowMobileNav(false);
    setInstaScroll(false);
    document.documentElement.style.overflow = 'auto';
    if(isMobile){
      setMobileNavCollapsed(false);
    }
  };

  const showMobileNavHandler = () => {

    document.documentElement.style.overflow = 'hidden';

    if (window.scrollY === 0) {
      window.scrollBy({ top: 100, behavior: "smooth" });

      setTimeout(() => {
        setMobileNavCollapsed(true);
        setShowMobileNav(true);
      }, 500);
    } else {
      setMobileNavCollapsed(true);
      setShowMobileNav(true);
    }
  };

  let isFetchingPdf = false; // Prevent multiple simultaneous calls

  const handleOpenPdf = async (restaurant) => {
    if (isFetchingPdf) {
      console.log("Already fetching the PDF. Exiting early.");
      return; // Prevent concurrent executions
    }

    if(restaurant === 'pulcinos'){
      setOpeningMenuPulcinos(true);
    }else if(restaurant === 'satiata'){
      setOpeningMenuSatiata(true);
    }
  
    console.log("Starting to fetch the PDF...");
    isFetchingPdf = true; // Lock the execution
  
    const maxRetries = 3; // Maximum number of retry attempts
    const retryDelay = 2000; // Delay between retries in milliseconds
  
    const fetchWithRetry = async (retries) => {
      for (let attempt = 1; attempt <= retries; attempt++) {
        console.log(`Attempt ${attempt} to fetch the menu for ${restaurant}`);
        try {
          const menu = await getActivePdf(restaurant);
          console.log(`Menu fetched (Attempt ${attempt}):`, menu);
          if (menu && typeof menu === "string" && (menu.startsWith("http") || menu.startsWith("blob:"))) {
            console.log("Valid menu URL found. Returning the menu.");
            return menu; // Return the menu URL if valid
          } else {
            console.log(`Invalid menu URL (Attempt ${attempt}):`, menu);
          }
        } catch (error) {
          console.error(`Error during fetch (Attempt ${attempt}):`, error);
        }
  
        // Wait before retrying (skip delay after the last attempt)
        if (attempt < retries) {
          console.log(`Waiting ${retryDelay}ms before next attempt...`);
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
        }
      }
      console.log("All attempts failed. Returning null.");
      return null; // Return null if all retries fail
    };
  
    try {
      const menu = await fetchWithRetry(maxRetries);
  
      if (menu) {
        console.log("Opening the menu:", menu);
         
         const link = document.createElement("a");
          link.href = menu; // The blob URL
          link.target = "_blank"; // Open in a new tab
          link.rel = "noopener noreferrer";

          // set name for download
          link.download = "Menu_Pulcinos.pdf";

          // Trigger the download or navigation
          link.click();
        console.log("Menu opened successfully. Exiting function.");
        return; // Exit the function immediately after opening the menu
      } else {
        console.log("Failed to fetch the menu after all retries.");
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    } finally {
      console.log("Resetting the isFetchingPdf flag.");
      isFetchingPdf = false; // Unlock execution after completing
      if(restaurant === 'pulcinos'){
        setOpeningMenuPulcinos(false);
      }else if(restaurant === 'satiata'){
        setOpeningMenuSatiata(false);
      }
    }
  };
 

  return (
    <>
      {isMobile && (
        <>
          <div
            className={`${mobileNavCollapsed ? "header-collapsed" : ""}`}
            id="mobileNavSpacer"
          ></div>

          
          <div onClick={hideMobileNav} className={`${showMobileNav ? "show-mobile-nav" : ""}`} id="mobileNavBackground"></div>

          <div
            className={`${(isHome &&isMobile)  ? "homeMobileNavBorder" : ""}
            ${mobileNavCollapsed   ? "header-collapsed" : ""}
            ${instaScroll ? "noTransition" : ""}`}
            id="mobileNavHeader"
          >
            <img src={logoWhite} alt="Logo" />
            <img onClick={showMobileNavHandler} src={burger} alt="Menu" />
          </div>
        </>
      )}

      <nav ref={navRef} 
        className={`${isCollapsed ? "nav-collapsed" : ""} ${
          !isVisible ? "nav-hidden" : ""
        } ${showMobileNav ? "show-mobile-nav" : ""}        
        `}
      >

        <div className="navInnerWrapper">
       
        {isMobile && (
          <img onClick={hideMobileNav} id="xSvg" src={x} alt="x"></img>
        )}

        {!isCollapsed && <img className="navLogo" src={logoWhite} alt="logo" />}
        <ul>
        <li>
              <Link
                to="/"
                onClick={() => handleLinkClick(isHome)}
                className={location.pathname === "/" ? "active-link" : ""}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/liquors#liquorBox"
                onClick={() => handleLinkClick(isHome)}
                className={location.pathname === "/liquors" ? "active-link" : ""}
              >
                Liquors
              </Link>
            </li>
            <li>
                <Link
                  to="/webshop#productBoxWrapper"
                  onClick={() => handleLinkClick(isHome)}
                  className={location.pathname === "/webshop" ? "active-link" : ""}
                >
                  Webshop
                </Link>
              </li>
            <li>
              <Link
                to="/restaurant#restrauntAnchor"
                onClick={() => handleLinkClick(isHome)}
                className={location.pathname === "/restaurant" ? "active-link" : ""}
              >
                Restaurant
              </Link>
            </li>
            <li>
              <Link
                to="/satiata/store#storeBox"
                onClick={() => handleLinkClick(isHome)}
                className={location.pathname === "/satiata/store" ? "active-link" : ""}
              >
                Satiata Liquorstore
              </Link>
            </li>
            <li>
              <Link
                to="/satiata/restaurant#satiataBox"
                onClick={() => handleLinkClick(isHome)}
                className={location.pathname === "/satiata/restaurant" ? "active-link" : ""}
              >
                Satiata Restaurant
              </Link>
            </li>
            <li>
              <Link
                to="/about#aboutBox"
                onClick={() => handleLinkClick(isHome)}
                className={location.pathname === "/about" ? "active-link" : ""}
              >
                Over ons
              </Link>
            </li>
            <li>
              <Link
                to="/contact#contactBox"
                onClick={() => handleLinkClick(isHome)}
                className={location.pathname === "/contact" ? "active-link" : ""}
              >
                Contact
              </Link>
            </li>     
            <li>
            <NavCart handleLinkClick={handleLinkClick} navRef={navRef} />
            </li> 
        </ul>

        {/* {isMobile && (

          // <div className="navBottomSpacer"></div>

        )} */}

        {isMobile && (

          <div className="navBottomButtons">
          <div onClick={() => isMenuSelect(true)} className="menuButton">Bekijk Menu</div>
          <div onClick={() => isReserveSelect(true)} className="reserveA reserveButton">Reserveer een tafel</div>
          </div>
        )}

        </div>
      </nav>

      
      {menuSelect && (
        <div id="menuSelectorBox">
          <div className="menuSelectorWrapper">
            <img onClick={() => isMenuSelect(false)} src={xCross} alt="x"></img>

            <h3>Welk menu wil je bekijken?</h3>

            <div className="menuSelectButtons">
              <button onClick={() => handleOpenPdf("pulcinos")}
                className="btn-pulci"
                disabled={isOpeningMenuPulcinos}>
                Menu Pulcinos
              </button>
              <button onClick={() => handleOpenPdf("satiata")}
                className="btn-satiata"
                disabled={isOpeningMenuSatiata}>
                Menu Satiata
              </button>
            </div>
          </div>
        </div>
      )}

      { reserveSelect && (
        <div id="menuSelectorBox">
          <div className="menuSelectorWrapper">
            <img onClick={() => isReserveSelect(false)} src={xCross} alt="x"></img>

            <h3>Waar wil je een tafel reserveren?</h3>

            <div className="menuSelectButtons">
               <a
                  href="https://restaurant-pulcinos.lurch.app/reservation/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="btn-pulci" >Pulcinos</button>
                </a>
                <a
                  href="https://pulcinos.lurch.app/reservation/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="btn-satiata" >Satiata</button>
                </a>
            </div>
          </div>
        </div>
      )}      
    
    </>
  );
}

export default Nav;

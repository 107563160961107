import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styling/restraunt.css";
import './styling/restraunt-s.css';
import pdfMenuPulcinos from "../pdf/latest_menu_pulcinos.pdf";
import mapsIconSatiata from "./assets/mapsSatiata.svg";
import pdfMenuSatiata from "../pdf/latest_menu_satiata.pdf";
import ImageSliderSatiata from "../components/SliderSatiata";
import octo from "./assets/octo.JPG";
import logoSatiata from "./assets/logoSatiata.svg";
import instaRed from "./assets/insta-red.svg";
import  { getActivePdf } from '../dataSource/PDFSource';


function SatiataRestaurant() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );
  const [isOpeningMenuSatiata, setOpeningMenuSatiata] = useState(false);

  let isFetchingPdf = false; // Prevent multiple simultaneous calls

  const handleOpenPdf = async (restaurant) => {

    const link = document.createElement("a");
    link.href = "";
    link.target = "_blank";
    document.body.appendChild(link);

    if (isFetchingPdf) {
      console.log("Already fetching the PDF. Exiting early.");
      return; // Prevent concurrent executions
    }
  
    console.log("Starting to fetch the PDF...");
    isFetchingPdf = true; // Lock the execution

    setOpeningMenuSatiata(true);
  
    const maxRetries = 3; // Maximum number of retry attempts
    const retryDelay = 2000; // Delay between retries in milliseconds
  
    const fetchWithRetry = async (retries) => {
      for (let attempt = 1; attempt <= retries; attempt++) {
        console.log(`Attempt ${attempt} to fetch the menu for ${restaurant}`);
        try {
          const menu = await getActivePdf(restaurant);
          console.log(`Menu fetched (Attempt ${attempt}):`, menu);
          if (menu && typeof menu === "string" && (menu.startsWith("http") || menu.startsWith("blob:"))) {
            console.log("Valid menu URL found. Returning the menu.");
            return menu; // Return the menu URL if valid
          } else {
            console.log(`Invalid menu URL (Attempt ${attempt}):`, menu);
          }
        } catch (error) {
          console.error(`Error during fetch (Attempt ${attempt}):`, error);
        }
  
        // Wait before retrying (skip delay after the last attempt)
        if (attempt < retries) {
          console.log(`Waiting ${retryDelay}ms before next attempt...`);
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
        }
      }
      console.log("All attempts failed. Returning null.");
      return null; // Return null if all retries fail
    };
  
    try {
      const menu = await fetchWithRetry(maxRetries);
  
      if (menu) {

        console.log("Opening the menu:", menu);
        
        link.href = menu;
        link.download = `Menu_${restaurant}.pdf`;
        link.click();

        // Trigger the downlo
        console.log("Menu opened successfully. Exiting function.");
        return; // Exit the function immediately after opening the menu
      } else {
        console.log("Failed to fetch the menu after all retries.");
        alert("Er is iets mis gegaan met het openen van het Menu...");
      }
    } finally {
      console.log("Resetting the isFetchingPdf flag.");
      isFetchingPdf = false; // Unlock execution after completing
      setOpeningMenuSatiata(false);
    }
  };

  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL (e.g., #team)
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      {!isMobile && (
    <div className="desktopNavSpacer"></div>
     )}
         <div id="satiataBox">
        <div id="satiataAnchor" className="splitterBox">
          <div className="imageBoxSatiata">
            <div>
              <ImageSliderSatiata />
            </div>
          </div>

          <div className="infoBox">
            <div className="infoImageWrapper">
              <img className="octoImage" src={octo} alt=""></img>
              <img
                className="logoImageSatiata"
                src={logoSatiata}
                alt="Satiata Logo"
              ></img>
            </div>

            <div className="infoWrapper">
              <h1>
                Welkom bij <span className="satiataRed">Satiata</span>
              </h1>

              <p>
                Satiata is het kleine zusje van Pulcinos, gelegen aan de
                Nieuwlandstraat in Tilburg. In onze gezellige, intieme zaak
                serveren we handgemaakte, verse gerechtjes die perfect te
                combineren zijn met een groot assortiment aan wijnen. De wijnen
                komen van onze eigen slijterij, pal naast de deur.
              </p>

              <p>
                Bij Satiata draait alles om genieten in een relaxte sfeer. Naast
                de vele wijnen kun je hier ook proeven van onze eigen Pulcinos
                likeuren en natuurwijnen. Kom langs voor een heerlijke avond vol
                smaken en gezelligheid!{" "}
              </p>

              <p>
                Bij Satiata ben je welkom voor lunch, diner of gewoon een borrel
                met een hapje.
              </p>

              <div className="buttonsBox">
                <a
                  href="https://pulcinos.lurch.app/reservation/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="checkout-btn">Reserveer een Tafel</button>
                </a>
                <button
                  className="checkout-btn"
                  onClick={() => handleOpenPdf("satiata")}
                >
                  Bekijk Menu
                </button>
                <a
                  href="https://maps.app.goo.gl/duKn74KsMC3eNcXWA"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="checkout-btn mapsButton">
                    <img src={mapsIconSatiata} alt=""></img>
                    Adres
                  </button>
                </a>
                <a
                  href="https://www.instagram.com/satiata.tilburg/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img className="instaLogo" src={instaRed} alt=""></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default SatiataRestaurant;

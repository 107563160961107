import React, { useState, useEffect } from 'react';
import { isAuthenticated } from "../util/protectedRoute";
import { Link, useNavigate } from "react-router-dom";
import './styling/login.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Login = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  useEffect(() => {

    const is_Authenticated = async () => {
      var userAuthenticated = false;
      const token = localStorage.getItem("Token");

      if (token) {
        try {
          const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode token
          var decodedUserId = decodedToken.sub;

          userAuthenticated = await isAuthenticated(decodedUserId);

          console.log(userAuthenticated);

        } catch {
          console.log("Token not in right format...");
        }
      }

      if (userAuthenticated == true){
        navigate(`/user/${decodedUserId}`)        
      }
    };

  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const maxRetries = 3; // Maximum number of retry attempts
    const retryDelay = 1000; // Delay between retries in milliseconds
    let attempts = 0;
    let success = false;

    while (attempts < maxRetries && !success) {
        try {
            attempts++;
            console.log(`Attempt ${attempts} of ${maxRetries}`);

            const response = await fetch(`${BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username,
                    password,
                }),
            });

            if (response.ok) {
                // Login successful
                const data = await response.json();
                localStorage.setItem('Token', data.Token); // Store token
                const decodedToken = JSON.parse(atob(data.Token.split('.')[1])); // Decode token
                const idFromToken = decodedToken.sub;
                window.location.href = `/admin`;
                success = true; // Exit retry loop
            } else {
                console.error(`Login failed (Attempt ${attempts})`);
            }
        } catch (error) {
            console.error(`Error during login attempt ${attempts}:`, error);
        }

        if (!success && attempts < maxRetries) {
            console.log(`Retrying in ${retryDelay}ms...`);
            await new Promise((resolve) => setTimeout(resolve, retryDelay)); // Delay before retrying
        }
    }

    if (!success) {
        console.error('Login failed after maximum retry attempts');
        alert('Login failed. Please try again later.');
    }
};


  return (

    <>
    {!isMobile && (
      <div className="desktopNavSpacer"></div>
       )}

    <div className="login-wrapper">
    <div className="login-box">
      <h2>Admin Login</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          placeholder="Username" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <button type="submit" className="btn btn-pulci-green">Login</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  </div>
  </>
  );
};

export default Login;
